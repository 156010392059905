.large-text {
  font-size: 2rem;
  margin: 1rem;
}

.small-text {
  font-size: 1rem;
  margin-top: 1rem;

}

.contact_wrapper{
  background-color: #4A4E8C;
  padding: 1rem;
  border-radius: 2rem;
}

.large_text {
  font-size: 1rem;
  text-decoration: underline;
  margin-top: 1rem;
  color: whitesmoke;
}

.small_text {
  font-size: 2rem;
  padding: 1rem;
  color: whitesmoke;
}


@media screen and (max-width: 991px) {
  .container {
    padding-right: 30px;
    padding-left: 30px;
  }
}

@media screen and (max-width: 768px) {

  .large_text {
    font-size: 1rem;
    text-decoration: underline;
    margin-top: 1rem;
    color: whitesmoke;
  }
  
  .small_text {
    font-size: 1.5rem;
    padding: 1rem;
    color: whitesmoke;
  }
}